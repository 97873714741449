import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { offsetsActions } from "../../store/offsets";

const Header = () => {
  const dispatch = useDispatch();
  const changeFlag = () => {
    dispatch(offsetsActions.setFlag());
  };
  const changeFlagDash = () => {
    window.location.href = process.env.REACT_APP_DASHBOARD;
    dispatch(offsetsActions.setFlag());
  };

  return (
    <header className={styles.headerContainer}>
      {/* <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
        }}
      >
        <div
          style={{ height: "100%", background: "#ffffff", width: "3px" }}
        ></div>
      </div> */}
      <div className={styles.content}>
        <div className={styles.a5Container}>
          <p className={styles.head}>Sauced Proxies </p>
          <p className={styles.desc}>
            Premium Residental and Private ISP Proxies{" "}
          </p>
          <div className={styles.btnsContainer}>
            <NavLink to="/products">
              {" "}
              <button className={styles.view} onClick={() => changeFlag()}>
                Purchase
              </button>
            </NavLink>

            <button className={styles.learn} onClick={() => changeFlagDash()}>
              Dashboard
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
