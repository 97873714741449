import { useEffect, useState } from "react";
import styles from "./Residentals.module.css";
import { useDispatch, useSelector } from "react-redux";
import { residentialActions } from "../../../../store/residential";
import CouponModal from "../../../UI/CouponModal/CouponModal";
const Residentals = (props) => {
  const dispatch = useDispatch();
  const capacity = useSelector(
    (state) => state.residential.saucedSheeeshPlanCapacity
  );
  const capacityPrice = useSelector(
    (state) => state.residential.saucedSheeeshCapacityPrice
  );
  const user = useSelector((state) => state.offset.user);

  useEffect(() => {
    dispatch(residentialActions.saucedSheeeshPrices("2GB"));
  }, []);

  const [isDisabled, setIsDisabled] = useState(false);
  const changePlan = (capacity) =>
    dispatch(residentialActions.saucedSheeeshPrices(capacity));

  const handleDecrement = () => {
    if (parseInt(capacity.replace("GB", "")) > 2) {
      let newCap = parseInt(capacity.replace("GB", "")) - 2;
      changePlan(`${newCap}GB`);
    }
  };

  const handleIncrement = () => {
    if (parseInt(capacity.replace("GB", "")) >= 2) {
      let newCap = parseInt(capacity.replace("GB", "")) + 2;
      changePlan(`${newCap}GB`);
    }
  };

  return (
    <div className={styles.usContainer}>
      <header className={styles.header}>
        <p className={styles.title}>SaucedSheeesh</p>
        <p className={styles.subTitle}>Residential</p>
      </header>
      <div className={styles.body}>
        <ul className={styles.list}>
          <li>Dedicated, Reliable and Real Residential IP's</li>
          {/* <li>Real Residential IPs from All Over the World</li> */}
          <li>Large IP Pool</li>
          {/* <li>Country, State, and ISP Level Targeting Available</li> */}
          {/* <li>Custom Pools for Specific Sites</li> */}
          <li>No Expiration</li>
          <li>Working on All Sites</li>
          <li>Unlimited Proxy Generation</li>
        </ul>
      </div>
      <div className={styles.btns}>
        <div className={styles.btnsContainer}>
          <button
            onClick={handleDecrement}
            disabled={capacity === "2GB" || isDisabled ? true : false}
          >
            -
          </button>
          <p>{capacity}</p>
          <button
            onClick={handleIncrement}
            disabled={isDisabled ? true : false}
          >
            +
          </button>{" "}
        </div>
      </div>
      <div className={styles.couponContainer}>
        {user && <CouponModal type="saucedSheeesh" setIsDisabled={setIsDisabled} />}
      </div>

      <div className={styles.price}>
        <p>{`$ ${capacityPrice}`}</p>
        <button
          onClick={() =>
            props.handleClick(
              capacityPrice,
              `${capacity} X Residential Proxies`
            )
          }
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};
export default Residentals;
