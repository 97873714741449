import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import FAQ from "../../components/FAQ/FAQ";
import Header from "../../components/Header/Header";
import Pricing from "../../components/Pricing/Pricing";
import Promise from "../../components/Promise/Promise";
// import Plans from "../../components/Plans/Plans";
// import Slider from "../../components/Slider/Slider";
// import styles from "./Home.module.css";

const Home = (props) => {
  const [features, setFeatures] = useState();
  const [products, setProducts] = useState();
  const [faq, setFaq] = useState();
  const [slider, setSlider] = useState();
  const flagTrigger = useSelector((state) => state.offset.flag);

  useEffect(() => {
    if (props.location.pathname === "/features") {
      window.scrollTo({
        top: features,
        left: 0,
        behavior: "smooth",
      });
    }
    if (props.location.pathname === "/products") {
      window.scrollTo({
        top: products + 220,
        left: 0,
        behavior: "smooth",
      });
    }
    if (props.location.pathname === "/faq") {
      window.scrollTo({
        top: faq,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [props.location.pathname, features, products, faq, slider, flagTrigger]);

  return (
    <Fragment>
      <Header />
      <Pricing setProducts={setProducts} />
      <Promise setFeatures={setFeatures} />
    </Fragment>
  );
};
export default withRouter(Home);
