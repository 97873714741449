import styles from "./Legal.module.css";

const Legal = (props) => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Legal</p>
          <p className={styles.desc}>
            We follow the law, so you have to also. Don't abuse the proxies, don't use them for spam, don't use them for other illegal things. We protect your privacy, but subpoenas trump privacy. 
          </p>
        </div>
      </div>
    </div>
  );
};
export default Legal;
