import styles from "./Sales.module.css";

const Sales = (props) => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Sales & Refunds</p>
          <p className={styles.desc}>
            Unfortunately, given the nature of how servers and proxies work, once your order has been delivered we do not offer refunds except under <strong>EXTREME</strong> circumstances. We reserve the right to review each case individually.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Sales;
