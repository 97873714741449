import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.items}>
            <p className={styles.rights}>
              © Proxies 2021. All rights reserved.{" "}
            </p>
            <NavLink to="/terms&conditions">
              {" "}
              <p className={styles.item}>Terms & Conditions</p>
            </NavLink>
            <NavLink to="/privacy-policy">
              <p className={styles.item}>Privacy Policy</p>
            </NavLink>

            <NavLink to="/sales">
              <p className={styles.item}>Sales & Refunds</p>
            </NavLink>

            <NavLink to="/legal">
              <p className={styles.item}>Legal</p>
            </NavLink>
          </div>
          <div className={styles.social}>
            <a
              href={process.env.REACT_APP_DISCORD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Group Copy 9">
                  <path
                    id="Combined Shape"
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.8914 3H5.10857C3.94629 3 3 3.94629 3 5.11886V19.0251C3 20.1977 3.94629 21.144 5.10857 21.144H16.7726L16.2274 19.2411L17.544 20.4651L18.7886 21.6171L21 23.5714V5.11886C21 3.94629 20.0537 3 18.8914 3ZM14.9211 16.4331C14.9211 16.4331 14.5509 15.9909 14.2423 15.6C15.5897 15.2194 16.104 14.376 16.104 14.376C15.6823 14.6537 15.2811 14.8491 14.9211 14.9829C14.4069 15.1989 13.9131 15.3429 13.4297 15.4251C12.4423 15.6103 11.5371 15.5589 10.7657 15.4149C10.1794 15.3017 9.67543 15.1371 9.25371 14.9726C9.01714 14.88 8.76 14.7669 8.50286 14.6229C8.48743 14.6126 8.472 14.6049 8.45657 14.5971C8.44114 14.5894 8.42571 14.5817 8.41029 14.5714C8.38971 14.5611 8.37943 14.5509 8.36914 14.5406C8.184 14.4377 8.08114 14.3657 8.08114 14.3657C8.08114 14.3657 8.57486 15.1886 9.88114 15.5794C9.57257 15.9703 9.192 16.4331 9.192 16.4331C6.91886 16.3611 6.05486 14.8697 6.05486 14.8697C6.05486 11.5577 7.536 8.87314 7.536 8.87314C9.01714 7.76229 10.4263 7.79314 10.4263 7.79314L10.5291 7.91657C8.67771 8.45143 7.824 9.264 7.824 9.264C7.824 9.264 8.05029 9.14057 8.43086 8.96571C9.53143 8.48229 10.4057 8.34857 10.7657 8.31771L10.7888 8.31382L10.7888 8.31381C10.8407 8.30498 10.8867 8.29714 10.9406 8.29714C11.568 8.21486 12.2777 8.19429 13.0183 8.27657C13.9954 8.38971 15.0446 8.67771 16.1143 9.264C16.1143 9.264 15.3017 8.49257 13.5531 7.95771L13.6971 7.79314C13.6971 7.79314 15.1063 7.76229 16.5874 8.87314C16.5874 8.87314 18.0686 11.5577 18.0686 14.8697C18.0686 14.8697 17.1943 16.3611 14.9211 16.4331ZM10.1383 11.6297C9.552 11.6297 9.08914 12.144 9.08914 12.7714C9.08914 13.3989 9.56229 13.9131 10.1383 13.9131C10.7246 13.9131 11.1874 13.3989 11.1874 12.7714C11.1977 12.144 10.7246 11.6297 10.1383 11.6297ZM13.8926 11.6297C13.3063 11.6297 12.8434 12.144 12.8434 12.7714C12.8434 13.3989 13.3166 13.9131 13.8926 13.9131C14.4789 13.9131 14.9417 13.3989 14.9417 12.7714C14.9417 12.144 14.4789 11.6297 13.8926 11.6297Z"
                    fill="#E0E0FF"
                  />
                </g>
              </svg>{" "}
            </a>
            <a
              href={process.env.REACT_APP_TWITTER_LINK}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Group 2 Copy">
                  <path
                    id="Path"
                    opacity="0.6"
                    d="M8.28 20.1261C15.824 20.1261 19.952 13.8701 19.952 8.45412C19.952 8.27812 19.952 8.10212 19.944 7.92612C20.744 7.35012 21.44 6.62212 21.992 5.79812C21.256 6.12612 20.464 6.34212 19.632 6.44612C20.48 5.94212 21.128 5.13412 21.44 4.17412C20.648 4.64612 19.768 4.98212 18.832 5.16612C18.08 4.36612 17.016 3.87012 15.84 3.87012C13.576 3.87012 11.736 5.71012 11.736 7.97412C11.736 8.29412 11.776 8.60612 11.84 8.91012C8.432 8.74212 5.408 7.10212 3.384 4.62212C3.032 5.23012 2.832 5.93412 2.832 6.68612C2.832 8.11012 3.56 9.36612 4.656 10.1021C3.984 10.0781 3.352 9.89412 2.8 9.59012C2.8 9.60612 2.8 9.62212 2.8 9.64612C2.8 11.6301 4.216 13.2941 6.088 13.6701C5.744 13.7661 5.384 13.8141 5.008 13.8141C4.744 13.8141 4.488 13.7901 4.24 13.7421C4.76 15.3741 6.28 16.5581 8.072 16.5901C6.664 17.6941 4.896 18.3501 2.976 18.3501C2.648 18.3501 2.32 18.3341 2 18.2941C3.808 19.4461 5.968 20.1261 8.28 20.1261Z"
                    fill="#E0E0FF"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
