import styles from "./Promise.module.css";
import functionality from "../../assets/images/function.svg";
import support from "../../assets/images/support.svg";
import uptime from "../../assets/images/uptime.svg";
import { useRef, useEffect } from "react";

const Promise = (props) => {
  const features = useRef();

  useEffect(() => {
    props.setFeatures(features.current.offsetTop);
  }, []);

  return (
    <section className={styles.promiseContainer} ref={features}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Our Promise</p>
          <p className={styles.desc}>
            We provide the highest quality residential and DC proxies. Your
            success is our success.{" "}
          </p>
        </div>
        <div className={styles.promises}>
          <div className={styles.promise}>
            <img
              src={functionality}
              alt="functionality"
              className={styles.functionality}
            />
            <p className={styles.promiseTitle}>Functionality</p>
            <p className={styles.promiseDesc}>
              You won't need to use any other provider.{" "}
            </p>
          </div>
          <div className={styles.promise}>
            <img src={support} alt="support" className={styles.support} />
            <p className={styles.promiseTitle}>Outstanding Support</p>
            <p className={styles.promiseDesc}>
              We are dedicated to providing all the assistance you may need.{" "}
            </p>
          </div>
          <div className={styles.promise}>
            <img src={uptime} alt="uptime" className={styles.uptime} />
            <p className={styles.promiseTitle}>99.99% Uptime</p>
            <p className={styles.promiseDesc}>
              Our proxies are hosted on large scale networks ensuring 99.99%
              functionality across all sites at all times.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Promise;
