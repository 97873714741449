import styles from "./Login.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Cookies from "js-cookie";
import { offsetsActions } from "../../store/offsets";
import discordButton from "../../assets/images/discord.svg";
import twitter from "../../assets/images/twitter.svg";
const Login = (props) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogin = async () => {
    if (Cookies.get("subUser")) {
      const response = await axiosInstance.get("auth/login");
      if (response.data.user) {
        localStorage.setItem("auth-user-sauce", response.data.user);
        dispatch(offsetsActions.login(response.data.user));
        history.push("/");
      } else {
        window.location.href = `${process.env.REACT_APP_API_URL}auth/discord/`;
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/discord/`;
    }
  };

  return (
    <div className={styles.loginContainer}>
      <svg
        onClick={() => props.closeModal()}
        className={styles.close}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
      >
        <g id="close" opacity="0.6" filter="url(#filter0_d)">
          <path
            id="shape"
            d="M18.0781 18.4531C18.4766 18.0469 18.4766 17.3828 18.0859 16.9922L13.4688 12.375L18.0859 7.75001C18.4766 7.35938 18.4844 6.68751 18.0781 6.28907C17.6797 5.89063 17.0078 5.89063 16.6172 6.28126L12 10.8984L7.375 6.28126C7 5.89844 6.3125 5.88282 5.91406 6.28907C5.51562 6.68751 5.52344 7.37501 5.90625 7.75001L10.5234 12.375L5.90625 16.9922C5.52344 17.375 5.51562 18.0547 5.91406 18.4531C6.32031 18.8516 7 18.8438 7.375 18.4688L12 13.8438L16.6172 18.4609C17.0078 18.8516 17.6797 18.8516 18.0781 18.4531Z"
            fill="#e0e0ff"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="1.61719"
            y="5.98926"
            width="20.7637"
            height="20.7637"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <div className={styles.content}>
        <div className={styles.title}>
          <p className={styles.login}>Log In</p>
          <p className={styles.getStarted}>to get started</p>
        </div>
        <button className={styles.discordBtn} onClick={handleLogin}>
          <img src={discordButton} alt="discord" className={styles.discord} />
          Sign in with Discord
        </button>
        {/* <button className={styles.twitterBtn}>
          <img src={twitter} alt="twitter" className={styles.discord} />
          Sign in with Twitter
        </button> */}
      </div>
    </div>
  );
};
export default Login;
