import styles from "./ISP.module.css";
import { useDispatch, useSelector } from "react-redux";
import { dcIspActions } from "../../../../store/dataCenter";
import { useEffect, useState } from "react";
import axios from "axios";
import CouponModal from "../../../UI/CouponModal/CouponModal";
const ISP = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);
  const [capacityValue, setCapacityValue] = useState("10 Proxies");
  const [maxAvailableAmount, setMaxAvailableAmount] = useState(0);

  const dispatch = useDispatch();
  const capacity = useSelector((state) => state.dc.planCapacity);
  const capacityPrice = useSelector((state) => state.dc.capacityPrice);
  const priceId = useSelector((state) => state.dc.priceId);
  const changePlan = (capacity) =>
    dispatch(dcIspActions.monthlyPrices(capacity));
  useEffect(() => {
    if (capacityValue === "10 Proxies") {
      changePlan(10);
    }
    if (capacityValue === "25 Proxies") {
      changePlan(25);
    }
    if (capacityValue === "50 Proxies") {
      changePlan(50);
    }
    if (capacityValue === "100 Proxies") {
      changePlan(100);
    }
  }, [capacityValue]);

  useEffect(() => {
    axiosInstance
      .get("ispsplan/available-proxies-count")
      .then(({ data }) => {
        console.log(data, "data");
        setMaxAvailableAmount(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className={styles.usContainer}>
      <header className={styles.header}>
        <p className={styles.title}>DC ISP</p>
        <div className={styles.brdrContainer}>
          {" "}
          <div className={styles.brdr}></div>{" "}
        </div>{" "}
        <p className={styles.priceCont}>
          $<span className={styles.priceText}>{capacityPrice}</span>
        </p>
      </header>
      <div className={styles.body}>
        <ul className={styles.list}>
          <li>User:Pass Auth // Unlimited Bandwidth</li>
          {/* <li>
            Currently functional on Footsites, Shopify, YeezySupply,Target,
            Walmart, Dicks, Supreme, Finishline, Topps, Amazon, Gamestop,
            Pokemon Store, Bestbuy, Hibbett and more.*
          </li> */}
          <li>Not guaranteed on Footsites & Nike after delivery</li>
        </ul>
        <p className={styles.functionality}>
          *Functionality is subject to change with new antibot and protection
          measures
        </p>
        <div className={styles.price}>
          <select
            disabled={isSelected ? true : false}
            value={capacityValue}
            onChange={(e) => {
              setCapacityValue(e.target.value);
            }}
          >
            {maxAvailableAmount < 10 && <option>No proxies</option>}
            {/* {maxAvailableAmount < 25 && <option>No proxies</option>} */}
            {maxAvailableAmount >= 10 && <option>10 Proxies</option>}
            {maxAvailableAmount >= 25 && <option>25 Proxies</option>}
            {maxAvailableAmount >= 50 && <option>50 Proxies</option>}
            {maxAvailableAmount >= 100 && <option>100 Proxies</option>}
          </select>
          {user && <CouponModal type="isp" setIsSelected={setIsSelected} />}
          <button
            disabled={maxAvailableAmount < 10 && user ? true : false}
            onClick={() =>
              props.handleClick(
                capacityPrice,
                `${capacity} X ISP Proxies`,
                priceId
              )
            }
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};
export default ISP;
