import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = (props) => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Privacy Policy</p>
          <p className={styles.desc}>
        Privacy, it's important to you and us. <br /><br />

	While we will keep your privacy to ourselves, there will be occasions where we will need to access your data; 
	like when you ask for technical support, when there's hardware issues, or when legal issues arise. 
	Even with those occasions, we maintain your privacy with the utmost care.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
