import styles from "./SaucedSheeesh.module.css";
import axios from "axios";
import ReactDOM from "react-dom";
import Modal from "../../UI/Modal/Modal";
import Spinner from "../../UI/Spinner/Spinner";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Login from "../../Login/Login";
import SideDrawer from "../../Navigation/SideDrawer/SideDrawer";
import { useState, useEffect, Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Residentals from "./Residentals/Residentals";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY);

const SaucedSheeesh = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);

  const [message, setMessage] = useState();
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success") && window.localStorage.getItem("saucedSheeeshcheckout")) {
      let subDataArr = window.localStorage.getItem("saucedSheeeshcheckout")?.split(",");
      let dataObj = {
        user_discord_id: subDataArr[0],
        amount: subDataArr[1],
        saucedType: subDataArr[2],
      };

      axiosInstance
        .post("secretplan/topup", dataObj)
        .then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setMessage("Your order was placed successfully!");
            setMessageModal(true);

            setTimeout(() => {
              window.localStorage.removeItem("saucedSheeeshcheckout");
            }, 1000);
            setTimeout(() => {
              window.location.href = process.env.REACT_APP_DASHBOARD;
            }, 1500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setShow(true);
      setLoading(true);
    }

    if (query.get("canceled")) {
      setShow(true);
      setMessage("Your order was cancelled!");
      setMessageModal(true);
    }
  }, []);

  const handleClick = async (price, name) => {
    if (user) {
      let user_discord_id = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[0]
        .split(":")[1]
        .slice(1, -1);
      const stripe = await stripePromise;
      setLoading(true);
      setShow(true);
      setSpinner(true);
      axiosInstance
        .post("create-checkout-session", {
          unit_amount: price * 100,
          name: name,
          domain: window.location.href,
          user_discord_id: user_discord_id,
          saucedType: "saucedSheeesh",
        })
        .then((res) => {
          stripe.redirectToCheckout({
            sessionId: res.data.id,
          });
          //form of localstorage item "user_discord_id,unti_amount,saucedType"
          let data_added = name.split(" ")[0].replace("GB", "");
          window.localStorage.setItem(
            "saucedSheeeshcheckout",
            `${user_discord_id},${data_added},saucedSheeesh`
          );
        })
        .catch((error) => setMessage(error.message));
    } else {
      showLoginFn();
    }
  };
  function closeModal() {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
    setMessageModal(false);
  }
  function closeModalLogin() {
    setShowLogin(false);
    setSideDrawer(false);
    setBackdrop(false);
  }

  function closeBackdrop() {
    setBackdrop(false);
    setSideDrawer(false);
  }
  function showLoginFn() {
    setShowLogin(true);
    setSideDrawer(false);
  }

  return (
    <Fragment>
      {!user &&
        ReactDOM.createPortal(
          <Modal
            show={showLogin}
            closeModal={closeModalLogin}
            messageModal={true}
          >
            <Login closeModal={closeModalLogin} />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {!user &&
        ReactDOM.createPortal(
          <SideDrawer
            show={sideDrawer}
            closeClicked={closeBackdrop}
            showLogin={showLoginFn}
          />,
          document.getElementById("sideDrawer-root")
        )}
      {loading
        ? ReactDOM.createPortal(
            <Modal
              closeModal={closeModal}
              show={show}
              spinner={spinner}
              stripe={loading}
            >
              <Spinner />
            </Modal>,
            document.getElementById("modal-root")
          )
        : ReactDOM.createPortal(
            <Modal
              closeModal={closeModal}
              show={show}
              stripe={loading}
              messageModal={messageModal}
            >
              <div className="message">
                <span>{message}</span>
                <button onClick={closeModal}>Ok</button>
              </div>{" "}
            </Modal>,
            document.getElementById("modal-root")
          )}
      <div className={styles.saucedSheeeshContainer}>
        <Residentals handleClick={handleClick} />
      </div>
    </Fragment>
  );
};
export default SaucedSheeesh;
