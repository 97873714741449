import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Terms.module.css";

const Terms = (props) => {
  const [termsFlag, setTermsFlag] = useState(false);
  const contactRef = useRef();
  const flagTrigger = useSelector((state) => state.offset.flag);

  useEffect(() => {
    if (props.location.pathname === "/contact-us") {
      window.scrollTo({
        top: contactRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
      setTermsFlag(true);
    }
    if (props.location.pathname === "/terms&conditions") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTermsFlag(false);
    }
  }, [props.location.pathname, flagTrigger]);
  return (
    <div className={styles.termsContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>The Important Stuff</p>
          <p className={styles.desc}>
            We want you to be satisfied, but there's some important things to talk about.
          </p>
        </div>
        <div className={styles.terms}>
          <p className={styles.termsTit}>Terms and Conditions:</p>
          <p className={styles.termsSubTit}>
            You (The Customer) of Sauce Servers LLC Acknowledges That:
          </p>
          <p className={styles.termsDesc}>
            - Refunds won't be provided except under <strong>EXTREME</strong> circumstances.
            <br />
            <br />
            - There will be no warranties on any product given.
            <br />
            <br />- Sauce Servers LLC will not be responsible for any throttles,
            bans, or any other issues due to misuse and abuse of proxies by the
            customer.
          </p>
          <p className={styles.termsTit}>Fulfillment Policy</p>
          <p className={styles.termsDesc}>
            Proxies will be delivered via dashboard or email to the customer.
          </p>
	  <p className={styles.termsTit}>Abuse</p>
          <p className={styles.termsDesc}>
            Abuse of the proxies will result in account removal without a refund. Monitoring at 1ms, using them for spam, using the proxies for illegal activity, etc. 
          </p>
          <p
            className={`${styles.termsTit} ${termsFlag ? styles.pop : null}`}
            ref={contactRef}
          >
            Contact us
          </p>
          <p className={styles.termsDesc}>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e‑mail at support@saucedproxies.com
          </p>
        </div>
      </div>
    </div>
  );
};
export default Terms;
