import styles from "./NavigationItems.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { offsetsActions } from "../../../store/offsets";
import { residentialActions } from "../../../store/residential";
import Cookies from "js-cookie";

const NavigationItems = (props) => {
  const user = useSelector((state) => state.offset.user);
  const dispatch = useDispatch();
  const changeFlag = () => dispatch(offsetsActions.setFlag());

  const handleLogout = () => {
    dispatch(offsetsActions.logout(null));
    localStorage.removeItem("auth-user-sauce");
    Cookies.remove("subUser");
    residentialActions.removeResedintialProxies([]);
  };
  return (
    <nav className={styles.navigationItems}>
      <ul>
        <NavLink to="/">
          <li
            onClick={() => {
              changeFlag();
              props.closed();
            }}
          >
            Home
          </li>
        </NavLink>
        <NavLink to="/products">
          <li
            onClick={() => {
              changeFlag();
              props.closed();
            }}
          >
            Purchase
          </li>
        </NavLink>
        <NavLink to="/contact-us">
          <li
            onClick={() => {
              props.closed();
              changeFlag();
            }}
          >
            Contact Us
          </li>
        </NavLink>
        <a href="https://sauceservers.com/" target="_blank" rel="noreferrer">
          <li
            onClick={() => {
              props.closed();
              changeFlag();
            }}
            className={styles.server}
          >
            Sauce Servers
          </li>
        </a>
        {!user && (
          <li>
            <button
              className={styles.navButtonRes}
              onClick={() => props.showLogin()}
            >
              Log In
            </button>
          </li>
        )}
        {user && (
          <li>
            <button className={styles.navButtonRes} onClick={handleLogout}>
              Log Out
            </button>
          </li>
        )}
      </ul>
      {!user && (
        <button className={styles.navButton} onClick={() => props.showLogin()}>
          Log In
        </button>
      )}
      {user && (
        <button className={styles.navButton} onClick={handleLogout}>
          Log Out
        </button>
      )}
    </nav>
  );
};
export default NavigationItems;
