import React, { Fragment, useState } from "react";
import styles from "./CouponModal.module.css";
import axios from "axios";
import { residentialActions } from "../../../store/residential";
import { ispActions } from "../../../store/isp";
import { dcIspActions } from "../../../store/dataCenter";
import { useDispatch, useSelector } from "react-redux";

const CouponModal = ({ type, setIsDisabled, setIsSelected }) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState("");
  const [flagApplied, setFlagApplied] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const edgeCapacityPrice = useSelector(
    (state) => state.residential.edgeCapacityPrice
  );

  const sagaCapacityPrice = useSelector(
    (state) => state.residential.sagaCapacityPrice
  );

  const saucedSheeeshCapacityPrice = useSelector(
    (state) => state.residential.saucedSheeeshCapacityPrice
  );

  const ispPrice = useSelector((state) => state.dc.capacityPrice);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "premium" || type === "lite" || type === "saucedSheeesh") {
      axiosInstance
        .post("secretplan/check-coupon", {
          saucedType: type,
          coupon: coupon,
        })
        .then(({ data }) => {
          // data is like {isExist: true, percent_off: 20}
          console.log(data);
          if (data.isExist) {
            setFlagApplied(true);
            setIsDisabled(true);
            setNotValid(false);
            // the coupon is valid
            // change edgeCapacityPrice
            if (type === "premium") {
              let newPrice =
                Number(edgeCapacityPrice) -
                (Number(edgeCapacityPrice) * data.percent_off) / 100;
              dispatch(
                residentialActions.changeEdgeCapacityPrice(newPrice.toFixed(2))
              );
            } else if (type === "lite") {
              let newPrice =
                Number(sagaCapacityPrice) -
                (Number(sagaCapacityPrice) * data.percent_off) / 100;
              dispatch(
                residentialActions.changeSagaCapacityPrice(newPrice.toFixed(2))
              );
            } else if (type === "saucedSheeesh") {
              let newPrice =
                Number(saucedSheeeshCapacityPrice) -
                (Number(saucedSheeeshCapacityPrice) * data.percent_off) / 100;
              dispatch(
                residentialActions.changeSaucedSheeeshCapacityPrice(
                  newPrice.toFixed(2)
                )
              );
            }
            // remove the input textbox and say coupon applied
          } else {
            setNotValid(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === "isp") {
      axiosInstance
        .post("check-coupon", {
          coupon: coupon,
        })
        .then(({ data }) => {
          // data is like {couponId: 'fTRljlwQ', isExist: true, percent_off: 20}
          console.log(data);
          if (data.isExist) {
            setFlagApplied(true);
            setIsSelected(true);
            setNotValid(false);
            let newPrice =
              Number(ispPrice) - (Number(ispPrice) * data.percent_off) / 100;
            dispatch(dcIspActions.changeCapacityPrice(newPrice.toFixed(2)));
            // ispPrice
            dispatch(ispActions.changeCouponID(data.couponId));
            // remove the input textbox and say coupon applied
          } else {
            setNotValid(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Fragment>
      {flagApplied ? (
        <p className={styles.couponLabel}>Coupon Applied!</p>
      ) : (
        <form className={styles.couponForm}>
          <input
            className={styles.coupon}
            type="text"
            placeholder="coupon"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
          <button onClick={handleSubmit} className={styles.add}>
            Add
          </button>
        </form>
      )}
      {notValid && <span className={styles.alert}>Coupon Not Valid!</span>}
    </Fragment>
  );
};
export default CouponModal;
