import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sagaPlanCapacity: "4GB",
  sagaCapacityPrice: 16,
  edgePlanCapacity: "2GB",
  edgeCapacityPrice: 32,
  saucedSheeeshPlanCapacity: "2GB",
  saucedSheeeshCapacityPrice: 12,
  thirdPlanCapacity: "2GB",
  thirdCapacityPrice: 44,
  proxyType: "sticky",
  proxyCountry: "",
  proxyQuantity: 0,
  resedintialProxies: [],
};
const residentialPricesFirst = {
  "3GB": 56,
  "6GB": 109,
  "9GB": 149,
  "12GB": 199,
};
const residentialPricesSecond = {
  "3GB": 56,
  "6GB": 109,
  "9GB": 149,
  "15GB": 199,
};
const residentialPricesThird = {
  "2GB": 44,
  "3GB": 66,
  "5GB": 110,
  "10GB": 220,
};

const saucedSheeeshPrices = {
  "2GB": 12,
  "4GB": 22,
  "6GB": 30,
  "8GB": 40,
  "10GB": 50,
  "20GB": 80,
  "30GB": 120,
};

const residentialSlice = createSlice({
  name: "residentialPlan",
  initialState,
  reducers: {
    sagaPrices(state, action) {
      state.sagaCapacityPrice = parseInt(action.payload.replace("GB", "")) * 4;
      state.sagaPlanCapacity = action.payload;
    },
    edgePrices(state, action) {
      state.edgeCapacityPrice = parseInt(action.payload.replace("GB", "")) * 16;
      state.edgePlanCapacity = action.payload;
    },
    // Added SaucedSheeesh prices
    saucedSheeeshPrices(state, action) {
      state.saucedSheeeshCapacityPrice =
        parseInt(action.payload.replace("GB", "")) * 6;
      state.saucedSheeeshPlanCapacity = action.payload;
    },
    changeEdgeCapacityPrice(state, action) {
      state.edgeCapacityPrice = action.payload;
    },
    changeSagaCapacityPrice(state, action) {
      state.sagaCapacityPrice = action.payload;
    },
    changeSaucedSheeeshCapacityPrice(state, action) {
      state.saucedSheeeshCapacityPrice = action.payload;
    },
    //
    thirdPrices(state, action) {
      state.thirdCapacityPrice = residentialPricesThird[action.payload];
      state.thirdPlanCapacity = action.payload;
    },
    changeProxyType(state, action) {
      state.proxyType = action.payload;
    },
    changeProxyCountry(state, action) {
      state.proxyCountry = action.payload;
    },
    changeProxyQuantity(state, action) {
      state.proxyQuantity = action.payload;
    },
    changeResedintialProxies(state, action) {
      state.resedintialProxies = action.payload;
      window.localStorage.setItem(
        "generated-resedintial-proxies",
        action.payload
      );
    },
    removeResedintialProxies(state, action) {
      state.resedintialProxies = action.payload;
      window.localStorage.removeItem("generated-resedintial-proxies");
    },
  },
});
export default residentialSlice.reducer;
export const residentialActions = residentialSlice.actions;
