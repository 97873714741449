import styles from "./Pricing.module.css";
import rect from "../../assets/images/rect.png";
import { useState, useEffect, useRef } from "react";
import Saga from "./Saga/Saga";
import Edge from "./Edge/Edge";
import SaucedSheeesh from "./SaucedSheeesh/SaucedSheeesh";
import Datacenter from "./DataCenter/DataCenter";

const Pricing = (props) => {
  const [toggle, setToggle] = useState("saga");
  const products = useRef();

  useEffect(() => {
    props.setProducts(products.current.offsetTop);
    if (localStorage.getItem("toggle")) {
      setToggle(localStorage.getItem("toggle"));
    }
  }, []);

  return (
    <section className={styles.pricingContainer} ref={products}>
      <img src={rect} alt="rectangle" className={styles.rect} />
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Proxies Pricing</p>
          <p className={styles.desc}>Pick the plan that fits you</p>
        </div>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              setToggle("saga");
              localStorage.setItem("toggle", "saga");
            }}
            style={
              toggle === "saga"
                ? { color: "#ff2c53", backgroundColor: "#ffffff", width: "21%" }
                : { color: "#ffffff", backgroundColor: "#ff2c53", width: "21%" }
            }
          >
            Sauced Lite Residential
          </button>
          <button
            style={
              toggle === "edge"
                ? { color: "#ff2c53", backgroundColor: "#ffffff" }
                : { color: "#ffffff", backgroundColor: "#ff2c53" }
            }
            onClick={() => {
              setToggle("edge");
              localStorage.setItem("toggle", "edge");
            }}
          >
            Sauced Premium Residential
          </button>
          {/* Added SaucedSheeesh */}
          <button
            style={
              toggle === "saucedSheeesh"
                ? { color: "#ff2c53", backgroundColor: "#ffffff" }
                : { color: "#ffffff", backgroundColor: "#ff2c53" }
            }
            onClick={() => {
              setToggle("saucedSheeesh");
              localStorage.setItem("toggle", "saucedSheeesh");
            }}
          >
            Sauced Sheeesh Residential
          </button>
          {/* End Added SaucedSheeesh */}

          <button
            style={
              toggle === "datacenter"
                ? { color: "#ff2c53", backgroundColor: "#ffffff" }
                : { color: "#ffffff", backgroundColor: "#ff2c53" }
            }
            onClick={() => {
              //setToggle("datacenter");
              //localStorage.setItem("toggle", "datacenter");
              window.location = "https://sauceservers.com/proxies";
            }}
          >
            Sauced DC ISP
          </button>
        </div>
        {toggle === "saga" && <Saga />}
        {toggle === "edge" && <Edge />}
        {toggle === "saucedSheeesh" && <SaucedSheeesh />}
        {toggle === "datacenter" && <Datacenter />}
      </div>
    </section>
  );
};
export default Pricing;
