import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  planCapacity: 10,
  capacityPrice: 16,
  period: "monthly",
  priceId: "price_1KBAITEMiRIxVc48fj0cRJtq",
};
const dcISPPrices = {
  10: 16,
  25: 40,
  50: 75,
  100: 140,
};
const dcISPPriceId = {
  10: "price_1KBAITEMiRIxVc48fj0cRJtq",
  25: "price_1Jd8oWEMiRIxVc48TepVLBvv",
  50: "price_1Jd8p9EMiRIxVc48UwKhjmxO",
  100: "price_1JI7O8EMiRIxVc485DiwI2Xn",
};

const dcIspSlice = createSlice({
  name: "ispPlan",
  initialState,
  reducers: {
    monthlyPrices(state, action) {
      state.capacityPrice = dcISPPrices[action.payload];
      state.planCapacity = action.payload;
      state.priceId = dcISPPriceId[action.payload];
    },
    changeCapacityPrice(state, action) {
      state.capacityPrice = action.payload;
    },
  },
});
export default dcIspSlice.reducer;
export const dcIspActions = dcIspSlice.actions;
