import styles from "./DataCenter.module.css";
import axios from "axios";
import ReactDOM from "react-dom";
import Modal from "../../UI/Modal/Modal";
import Spinner from "../../UI/Spinner/Spinner";
import { residentialActions } from "../../../store/residential";
import { useSelector } from "react-redux";
import Login from "../../Login/Login";
import SideDrawer from "../../Navigation/SideDrawer/SideDrawer";

import { useState, useEffect, Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";

import Residentals from "./Residentals/Residentals";
import ISP from "./ISP/ISP";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY);

const Datacenter = () => {
  const planCapacity = useSelector((state) => state.dc.planCapacity);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  <option>Select Country</option>;
  const [message, setMessage] = useState();
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [toggle, setToggle] = useState("monthly");
  const [showLogin, setShowLogin] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);
  const user = useSelector((state) => state.offset.user);
  const couponID = useSelector((state) => state.isp.couponID);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success") && window.localStorage.getItem("ispdcsubscr")) {
      let subDataArr = window.localStorage.getItem("ispdcsubscr")?.split(",");
      let dataObj = {
        username: subDataArr[0],
        unit_amount: subDataArr[1],
        period: subDataArr[2],
        user_discord_id: subDataArr[3],
        sessionid: subDataArr[4],
      };

      axiosInstance
        .post("ispsplan/add-new-plan", dataObj)
        .then(({ data }) => {
          if (data.targetUser) {
            setLoading(false);
            setMessage("Your order was placed successfully!");
            setMessageModal(true);
            setTimeout(() => {
              window.localStorage.removeItem("ispdcsubscr");
            }, 1000);
            setTimeout(() => {
              window.location.href = process.env.REACT_APP_DASHBOARD;
            }, 1500);
          } else {
            setLoading(false);
            setMessage(data.message);
            setMessageModal(true);
            window.localStorage.removeItem("ispdcsubscr");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setShow(true);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setMessage("Your order was placed successfully!");
        setMessageModal(true);
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_DASHBOARD;
        }, 1500);
      }, 21000);
    }

    if (query.get("canceled")) {
      setShow(true);
      setMessage("Your order was cancelled!");
      setMessageModal(true);
    }
  }, []);

  const handleClick = async (price, name, id) => {
    if (user) {
      let username = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[1]
        .split(":")[1]
        .slice(1, -1);
      let user_discord_id = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[0]
        .split(":")[1]
        .slice(1, -1);
      const stripe = await stripePromise;
      setLoading(true);
      setShow(true);
      setSpinner(true);
      let dataObj = {};
      if (couponID) {
        dataObj = {
          unit_amount: price * 100,
          name: name,
          domain: window.location.href,
          priceId: id,
          period: toggle,
          username: username,
          user_discord_id: user_discord_id,
          // here I'll add the couponID
          couponID: couponID,
        };
      } else {
        dataObj = {
          unit_amount: price * 100,
          name: name,
          domain: window.location.href,
          priceId: id,
          period: toggle,
          username: username,
          user_discord_id: user_discord_id,
        };
      }
      axiosInstance
        .post("create-subscription-session", dataObj)
        .then((res) => {
          stripe.redirectToCheckout({
            sessionId: res.data.id,
          });
          //form of localstorage item "username,unti_amount,period,user_discord_id,session_id"
          localStorage.setItem(
            "ispdcsubscr",
            `${username},${planCapacity},${toggle},${user_discord_id},${res.data.id}`
          );
        })
        .catch((error) => {
          setMessage(error.message);
          setMessageModal(true);
          setShow(true);
        });
    } else {
      showLoginFn();
    }
  };
  const closeModal = () => {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
    setMessageModal(false);
    setLoading(false);
    setSpinner(false);
  };
  function closeModalLogin() {
    setShowLogin(false);
    setSideDrawer(false);
    setBackdrop(false);
  }

  function closeBackdrop() {
    setBackdrop(false);
    setSideDrawer(false);
  }
  function showLoginFn() {
    setShowLogin(true);
    setSideDrawer(false);
  }

  return (
    <Fragment>
      {!user &&
        ReactDOM.createPortal(
          <Modal
            show={showLogin}
            closeModal={closeModalLogin}
            messageModal={true}
          >
            <Login closeModal={closeModalLogin} />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {!user &&
        ReactDOM.createPortal(
          <SideDrawer
            show={sideDrawer}
            closeClicked={closeBackdrop}
            showLogin={showLoginFn}
          />,
          document.getElementById("sideDrawer-root")
        )}
      {loading
        ? ReactDOM.createPortal(
            <Modal
              closeModal={closeModal}
              show={show}
              spinner={spinner}
              stripe={loading}
            >
              <Spinner />
            </Modal>,
            document.getElementById("modal-root")
          )
        : ReactDOM.createPortal(
            <Modal
              closeModal={closeModal}
              show={show}
              // stripe={loading}
              messageModal={messageModal}
            >
              <div className="message">
                <div>
                  <p className={styles.modalMessage}>{message}</p>
                  <button onClick={closeModal}>Ok</button>
                </div>
              </div>{" "}
            </Modal>,
            document.getElementById("modal-root")
          )}
      <div className={styles.toggleContainer}>
        <button
          onClick={() => setToggle("monthly")}
          style={
            toggle === "monthly"
              ? { color: "#ff2c53", backgroundColor: "#ffffff" }
              : { color: "#ffffff", backgroundColor: "#ff2c53" }
          }
        >
          Monthly
        </button>
      </div>
      <div className={styles.sagaContainer}>
        <ISP handleClick={handleClick} />
      </div>
    </Fragment>
  );
};
export default Datacenter;
