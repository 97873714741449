import { useEffect, useState, Fragment } from "react";
import { Route, Switch } from "react-router";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import Terms from "./pages/Terms/Terms";
import Cookies from "js-cookie";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";

import { offsetsActions } from "./store/offsets";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import Legal from "./pages/Legal/Legal";
import Sales from "./pages/Sales/Sales";

import Modal from "./components/UI/Modal/Modal";
import Login from "./components/Login/Login";
import SideDrawer from "./components/Navigation/SideDrawer/SideDrawer";

const App = () => {
  const user = useSelector((state) => state.offset.user);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);
  function closeModalLogin() {
    setShowLogin(false);
    setSideDrawer(false);
    setBackdrop(false);
  }

  // useEffect(() => {
  //   if (!user) {
  //     setShowLogin(true);
  //   }
  // }, [user]);

  const closeModal = () => {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  };
  function closeBackdrop() {
    setBackdrop(false);
    setSideDrawer(false);
  }
  function showLoginFn() {
    setShowLogin(true);
    setSideDrawer(false);
  }

  useEffect(() => {
    checkAfterDiscordAuth();
    window.addEventListener("load", () => setLoad(false));
    return () => window.removeEventListener("load", () => setLoad(false));
  }, []);
  function checkAfterDiscordAuth() {
    if (Cookies.get("subUser")) {
      axiosInstance
        .get("auth/login")
        .then((response) => {
          if (response.status === 200) {
            dispatch(offsetsActions.login(response.data.user));
            localStorage.setItem("auth-user-sauce", response.data.user);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  return (
    <Fragment>
      {!user &&
        ReactDOM.createPortal(
          <Modal
            show={showLogin}
            closeModal={closeModalLogin}
            messageModal={true}
          >
            <Login closeModal={closeModal} />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {!user &&
        ReactDOM.createPortal(
          <SideDrawer
            show={sideDrawer}
            closeClicked={closeBackdrop}
            showLogin={showLoginFn}
          />,
          document.getElementById("sideDrawer-root")
        )}
      {load ? (
        <div style={{ width: "100vw", height: "100vh" }}></div>
      ) : (
        <Layout>
          <Switch>
            <Route path="/terms&conditions" component={Terms} />
            <Route path="/contact-us" component={Terms} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/legal" component={Legal} />
            <Route path="/sales" component={Sales} />
            <Route path="/" component={Home} />
          </Switch>
        </Layout>
      )}
    </Fragment>
  );
};

export default App;
